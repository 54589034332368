// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/api/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"c3c4cbc9fa89931f24217880bddca0b508374f23"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { env } from '@prismo-io/env';
import { init, replayIntegration } from '@sentry/nextjs';
import { matchesUA } from 'browserslist-useragent';

if (env.NEXT_PUBLIC_SENTRY_DSN) {
  init({
    environment: env.NEXT_PUBLIC_APP_ENV,

    dsn: env.NEXT_PUBLIC_SENTRY_DSN,

    ignoreErrors: [/^TypeError:/, /^AuthApiError/, /ResizeObserver/],

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],

    beforeSend(event, _hint) {
      const swrHandler =
        event.extra && 'swr:handler' in event.extra
          ? event.extra['swr:handler']
          : false;

      if (swrHandler) {
        return null;
      }

      const userAgent = event.request?.headers?.['User-Agent'];
      if (userAgent) {
        const isMatched = matchesUA(userAgent, {
          allowHigherVersions: true,
        });

        if (!isMatched) {
          return null;
        }
      }

      return event;
    },
  });
}
